@mixin defaultButtonStyle {
   cursor: pointer;
   background: $primary-color;
   color: #fff;
   border: 2px solid $primary-color;
   font-size: 0.9rem;
   line-height: 1.5;
   border-radius: 5px;

   &:hover {
      color: $primary-color;
      background: #fff;
   }
}

.hidden { display: none; }

.vacancy {
   &__header {
      padding-top: $column-gutter-double;
   }

   &__container {
      display: flex;
      flex-direction: column;
   }

   &__search-container {
      @include moduleMargin;

      padding: $column-gutter-double;
      padding-bottom: 0;
      background: $secondary-background-color;

      &--query {
         position: relative;

         input {
            width: 100%;
            border-radius: 5px;
            margin-bottom: 5px;
            padding:
               $column-gutter-half
               $column-gutter-double
               $column-gutter-half
               $column-gutter-half;
            line-height: 1.5;
         }

         .remove-text {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 5px;
            font-size: 110%;
            padding: $column-gutter-half;
         }
      }

      &--options-button {
         text-align: center;
         padding: $column-gutter 0;

         @include tablet {
            text-align: left;
            padding: 0;
         }
      }

      &--options {
         transition: all 150ms;
         opacity: 0;
         height: 0;
         visibility: hidden;
         display: flex;
         align-items: center;
         padding-bottom: 0;
         justify-content: flex-start;

         @include tablet {
            height: 0%;
         }

         &-open {
            padding-top: 0;
            padding-bottom: $column-gutter-double;
            visibility: visible;
            opacity: 1;
            height: 100%;

            @include tablet {
               padding-top: $column-gutter;
            }
         }

         .options-spacer {
            padding-left: 0;
            padding-top: $column-gutter;
            width: 100%;
            text-align: center;

            &:first-child {
               padding-top: 0;
            }

            &:last-child { // reset filter btn column
               padding-top: $column-gutter-double;

               @include mobile { padding-top: 0; }
            }

            @include tablet {
               padding-top: 0;
               padding-left: $column-gutter-double;
               width: auto;
               text-align: left;

               &:first-child {
                  padding-left: 0;
               }
   
               &:last-child { // reset filter btn column
                  padding-top: 0;
                  padding-left: calc(#{$column-gutter-double} + 10px);
               }
            }

            label {
               display: inline-block;
               width: 100%;
               margin-bottom: $column-gutter-quarter;

               @include tablet {
                  width: auto;
                  margin-bottom: 0;
               }
            }

            select {
               padding: 6px;
               margin-left: $column-gutter-quarter;
               border-radius: 5px;
               border: 2px solid #00205b;
               min-width: 130px;
            }

            button {
               @include defaultButtonStyle;

               padding: $column-gutter-quarter $column-gutter-half;
            }
         }
      }

      .input-header {
         display: block;
         font-size: 0.9rem;
         padding-bottom: $column-gutter-half;
      }

      .box-left {
         padding-right: 0;
         position: relative;

         @include tablet {
            padding-right: $column-gutter;
         }
      }

      .box-right {
         padding-left: 0;
         padding-top: $column-gutter;

         @include tablet {
            padding-left: $column-gutter;
            padding-top: 0;
         }
      }

      .button-options {
         cursor: pointer;
         background: none;
         border: 0;
         padding: $column-gutter-half;
         margin-top: calc(#{$column-gutter-half} * -1);
         margin-left: calc(#{$column-gutter-half} * -1);
         font-size: 0.9rem;
         color: $primary-color;
         transition: all 150ms;

         &::after {
            content: "\e919";
            display: inline-block;
            font-family: icomoon, Helvetica, Arial, sans-serif;
            font-size: 10px;
            position: relative;
            right: -5px;
            transform: rotate(180deg);
            transition: transform 150ms;
         }

         &:hover {
            color: $secondary-color;
            text-decoration: underline;
         }

         &--open {
            &::after {
               transform: rotate(270deg);
            }

            @include tablet {
               padding-top: 0;
            }
         }
      }

      .button-search {
         @include defaultButtonStyle;

         padding: $column-gutter-half $column-gutter;
      }
   }

   &__pagination-container {
      @include moduleMargin;

      display: flex;
      justify-content: space-between;
      align-items: center;

      &--desktop {
         display:  none;
         
         @include tablet { display: flex; }
      }

      &--mobile {
         justify-content: center;
         flex-wrap: wrap;
         
         @include tablet { display: none; }

         .pagination-info { width: 100%; }

         .button-pagination {
            margin-top: $column-gutter;
            
            &--previous { margin-right: $column-gutter-half; }
            &--next { margin-left: $column-gutter-half; }
         }
      }

      .button-pagination {
         @include defaultButtonStyle;

         padding: 0 $column-gutter-quarter 3px;

         @include tablet {
            padding: 3px $column-gutter-half 5px;
         }

         &:disabled {
            background: $secondary-background-color;
            border: 2px solid $secondary-background-color;
            color: #909090;
            cursor: not-allowed;
         }

         &::before,
         &::after {
            transform: scaleX(1.2);
            font-size: 1.2rem;
            display: inline-block;
            line-height: 100%;
         }

         &--previous {
            &::before {
               content: '\2039';
               margin-right: $column-gutter-half;
            }
         }

         &--next {
            &::after {
               content: '\203A';
               margin-left: $column-gutter-half;
            }
         }
      }

      .pagination-info {
         font-weight: bold;
         font-size: 0.9rem;
         text-align: center;
      }
   }

   &__list-container {
      @include moduleMargin;

      .jobs {
         padding: 0;
         margin: 0;
         list-style: none;

         li {
            border: 2px solid transparent;
            border-radius: 3px;

            &:hover {
               border: 2px solid $primary-color !important;
            }

            &:nth-child(odd) {
               background: $secondary-background-color;
            }

            &:nth-child(even):last-child {
               border-bottom: 2px solid $secondary-background-color;
            }

            a {
               display: flex;
               flex-flow: row wrap;
               text-decoration: none;
               padding: $column-gutter-half;

               @include tablet { flex-flow: row nowrap; }
            }

            .title,
            .address,
            .company,
            .deadline {
               padding: 0 $column-gutter-half;
               line-height: 1.5;
            }

            .address,
            .company,
            .deadline {
               font-size: 0.9rem;

               @include tablet {
                  font-size: 1rem;
               }
            }

            .title,
            .address,
            .company,
            .company-and-deadline {
               width: 100%;
            }

            .title {
               font-size: 105%;
               font-weight: bold;
               
               @include tablet {
                  width: 35%;
                  font-size: 100%;
                  font-weight: normal;
               }
            }

            .address {
               @include tablet { width: 30%; }
            }

            .company-and-deadline {
               @include tablet { width: 35%; }

               .company {
                  width: 50%;
                  display: block;
                  float: left;
               }

               .deadline {
                  width: 50%;
                  display: block;
                  float: right;
                  text-align: right;
                  font-style: italic;

                  @include tablet {
                     font-style: normal;
                  }
               }
            }
         }
      }

      .no-jobs,
      .no-results {
         color: #8a6d3b;
         background: #fcf8e3;
         border: 2px solid #faebcc;
         border-radius: 5px;
         padding: $column-gutter;
         width: 100%;
         text-align: center;
      }
   }

   &__alert-container {
      @include moduleMargin;

      padding: $column-gutter-double;
      background: $secondary-background-color;
      text-align: center;
   
      .heading {
         font-size: 1.2rem;
         font-weight: bold;
         padding-bottom: $column-gutter-half;
      }
   
      input {
         border-radius: 5px;
         line-height: 1.5;
         padding: $column-gutter-quarter $column-gutter-half;
         margin-right: $column-gutter-half;
         text-align: center;
         min-width: 50px;

         @include tablet {
            margin: 0 $column-gutter-half;
         }
      }
   
      button {
         @include defaultButtonStyle;
   
         padding: $column-gutter-quarter $column-gutter-half;

         &::before {
            content: url('../images/icons/email-icon-white.svg');
            width: 17px;
            margin-right: $column-gutter-quarter;
            display: inline-block;
         }

         &:hover::before {
            content: url('../images/icons/email-icon-blue.svg');
         }
      }
   
      .input-container {
         width: 100%;
         display: inline-block;
         padding-top: $column-gutter;
   
         @include tablet {
            width: auto;
            padding-top: 0;
         }
      }
   }
}
