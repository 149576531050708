/*
 * custom or override styles
 * - only add styles here that are TINE specific
 * - if style is shared then update azure-tiff instead
 */

// page-intro
.o-intro {
   &__link {
      &:hover {
         .o-intro__preface {
            text-decoration: none;
         }

         .o-intro__heading {
            text-decoration: underline;
         }
      }
   }
}
