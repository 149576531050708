.list-person {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: flex-start;
}

.content-media {
  display: contents;
}

.list-person__person {
  overflow: hidden;
  display: flex;
  align-items: top;
  justify-content: center;
  flex-direction: column;
  background: $primary-background-color;
  border: $tine-default-border;
  flex-basis: 100%;
  margin: 0 $column-gutter/2 $column-gutter/2;

  @include tablet() {
    flex: 1 0 25%;
    max-width: calc(25% - #{$column-gutter});

    &:nth-child(4n+0) {
      margin-right: 0;
    }
  }
}

.list-person__text {
  padding: $column-gutter / 2;
}

.list-person__role {
  margin-top: 0;
}

.list-person__download {
  display: block;
}

.list-person__expand {
  /* Remove all decorations to look like normal text */
  background: none;
  border: none;
  display: inline;
  font: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  outline-offset: 0;

  /* Additional styles to look like a link */
  color: $link-color;
  cursor: pointer;
  text-decoration: $link-text-decoration;

  &:hover {
    color: $link-color-hover;
    text-decoration: $link-text-decoration-hover;
  }
}

.list-person__more {
  display: none;
}

.list-person__more--open {
  display: block;
}
