.plant-info {
  display: flex;
  flex-flow: row wrap;

  @media only screen and (max-width: 40em) {
    & > * {
      flex: 1 1 100%;
      padding-left: 10px;
    }
  }

  .plant-info__heading {
    text-align: left;
    font-size: 1.1em;
    margin-bottom: 5px;
  }

  .plant-info__data {
    margin-top: 0;
  }
}

.plant-info__wrapper {
  padding: $column-gutter/2;
}

.plant-info__contact {
  margin: 0;
  margin-bottom: 20px;
}

.plant-info__contact-property {
  display: block;
  margin-bottom: 3px;
}
