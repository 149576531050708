.box-card-grid {
   @include moduleMargin;

   a { text-decoration: none; }
   
   &__container {
      margin: $column-gutter-quarter;

      @include tablet {
         margin: $column-gutter-half;
      }
   }

   &__image {
      width: 100%;
      height: 165px;
      display: block;
      object-fit: cover;

      @include tablet {
         height: 175px;
      }
   }

   &__text {
      padding: 0 $column-gutter-half;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65px;

      @include tablet {
         padding: 0 $column-gutter;
         font-size: 110%;
         height: 75px;
      }

      h2 {
         font-size: 100%;
         
         @include tablet {
            font-size: 110%;
         }
      }
   }
}
