@mixin content-flex($percent) {
  flex-basis: $percent;
  flex-grow: 1;
  flex-shrink: 0;
}

.content-flex-20 {
  @include content-flex(20%);
}

.content-flex-25 {
  @include content-flex(25%);
}

.content-flex-40 {
  @include content-flex(40%);
}

.content-flex-50 {
  @include content-flex(50%);
}

.content-flex-60 {
  @include content-flex(60%);
}

.content-flex-100 {
  @include content-flex(100%);
}

@include mobile() {
  .content-flex-40 {
    @include content-flex(100%);
  }

  .content-flex-50 {
    @include content-flex(100%);
  }

  .content-flex-60 {
    @include content-flex(100%);
  }
}
