.vacancy-list {
  margin: $column-gutter / 2;
}

.vacancy-list__wrapper {
  overflow-x: scroll;
}

.vacancy-list__table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.vacancy-list__table-head {
  text-align: left;
  font-weight: bold;
}

.vacancy-list__table-head-cell {
  padding: $column-gutter / 2;
}

.vacancy-list__table-row {
  &:nth-child(odd) {
    background-color: $secondary-background-color;
  }

  &:nth-child(even) {
    background-color: $primary-background-color;
  }
}

.vacancy-list__table-cell {
  border-top: $tine-default-border;
  padding: $column-gutter / 2;
}
