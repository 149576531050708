$oneThird: calc(100% / 3); // 33.3333..% 

.grid {
   display: flex;
   flex-flow: row wrap;
   align-items: flex-start;
   justify-content: center;

   &--center-all {
      align-items: center;
      align-content: center;
      text-align: center;
   }

   &--center-content {
      text-align: center;
   }

   /*
   * columns in numbers
   */
   &__col-1 { // 100
      width: 100%;
   }

   &__col-2 { // 100 - 50
      width: 100%;

      @include tablet { width: 50% }
   }

   &__col-3 { // 100 - 50 - 33
      width: 100%;

      @include tablet { width: 50%; }
      @include desktop { width: $oneThird; }
   }

   &__col-4 { // 100 - 50 - 33 - 25 
      width: 100%;

      @include between(400px, $tablet) { width: 50%; }
      @include tablet { width: $oneThird; }
      @include desktop { width: 25% }
   }

   /*
    * columns in percentages
    */
   &__col-25 { // 100 - 25
      width: 100%;

      @include tablet { width: 25%; }
   }

   &__col-75 { // 100 - 75
      width: 100%;

      @include tablet { width: 75%; }
   }
}
