// TODO: add this part to xp-lib-brand and update tine + diplomis
// ---- Quick fix: add missing SCSS vars from Diplom-Is ----
@function calculate-rem($size, $base-size: 18px) {
  $sizeRem: ($size / $base-size) + 'rem';

  @return $sizeRem;
}

$z-indexs: (
  modal: 200,
  submenu: 20,
  history-line: 100,
  product-box-figure: 4,
  drop-list-nav: 1000,
  logo-link: 100,
  header-menu: 1020,
  mobile-overlay: -8,
  search-box: 10,
  header-container: 2
);

// overwrite colors with TINE profile
$light-blue: $alternative-color-lightblue;
$light-green: $alternative-color-green;
$light-orange: $alternative-color-orange;
$light-grey: $bg-recipe-grey;
$black: $primary-color;

$border-radius-box: calculate-rem(10px);
$border-radius-boxCard: calculate-rem(20px);
$iconSize: calculate-rem(23px);

// leave font vars as-is for now - tine use mainly h1-h6
$font-title-medium: calculate-rem(30px);
$font-normal: calculate-rem(20px);
$font-small: calculate-rem(16px);
$line-height-medium: calculate-rem(30px);
$line-height-small: calculate-rem(24px);
$line-height-big: calculate-rem(42px);

$history-line-thickness: 2.34rem;
// ------------------------------------------------

// box styles
.history-line {
  position: absolute;
  width: $history-line-thickness;
  height: 6rem;
  left: calc(50% - (#{$history-line-thickness} / 2));
  top: -3.9rem;
  content: "";
  background: url("../../images/graphics/history-line-middle.svg") 50% 50% no-repeat padding-box;
  border: none;
  z-index: map-get($z-indexs, history-line);

  &__first-event {
    height: 12.5rem;
    margin: -11.8rem 0 0 -1.2rem;
    top: 0;
    margin-top: 1.6rem;
    background: url("../../images/graphics/history-line-start.svg") 50% 50% no-repeat padding-box;

    @include desktop() {
      margin-top: 1.8rem;
    }
  }

  &__card {
    margin-top: 0;

    &--first-event {
      padding-top: 12rem;
      margin-top: 0;
    }
  }
}

.di-m-card {
  &__content {
    text-align: center;
    max-height: initial;
    padding: 1.667rem 1.111rem;

    &--blue {
      background: $light-blue;
    }

    &--green {
      background: $light-green;
    }

    &--orange {
      background: $light-orange;
    }

    &--grey {
      background: $light-grey;
    }
  }

  @include tablet() {
    max-height: 23.333rem;
    padding: 2.222rem 4.444rem;
  }

  @include desktop() {
    padding: 4.444rem;
  }

  &__rounded-corners {
    &--right {
      border-bottom-left-radius: $border-radius-box;
      border-bottom-right-radius: $border-radius-box;

      @include tablet() {
        border-radius: 0;
        border-top-right-radius: $border-radius-boxCard;
        border-bottom-right-radius: $border-radius-boxCard;
      }
    }

    &--left {
      border-top-left-radius: $border-radius-box;
      border-top-right-radius: $border-radius-box;

      @include tablet() {
        border-radius: 0;
        border-top-left-radius: $border-radius-boxCard;
        border-bottom-left-radius: $border-radius-boxCard;
      }
    }

    &--full {
      border-radius: $border-radius-boxCard;
      padding: 1.944rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__link {
    margin: 1.6rem 0;

    &--min-20 {
      min-height: 20rem;
    }

    &:hover {
      .m-card__heading {
        text-decoration: none;
      }
    }
  }

  &__heading {
    margin: 0;
    color: $primary-color;
    font-size: $font-normal;
    line-height: $line-height-medium;
    font-weight: 600;

    @include tablet() {
      font-size: $font-title-medium;
      line-height: $line-height-big;
    }
  }

  // overwrite intricate nested style
  &--featured .di-m-card__image img {
    min-height: auto;
    max-height: 16.667rem;

    @include tablet() {
      max-height: 100%;
    }
  }

  &__preface {
    margin: 0.556rem 0 0 0;
    font-size: $font-small;
    line-height: 1.167rem; // $line-height-smaller is too small
    color: $black;
    padding-bottom: 0;
    max-width: 52.778rem;
    align-self: center;

    // prevent <p> to make extra margins top/bot
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @include tablet() {
      margin: 1.111rem 0 0 0;
      font-size: $font-normal;
      line-height: $line-height-medium;
    }
  }

  &__read-more-btn {
    margin: 1.111rem 0 0 0;

    @include tablet() {
      margin: 1.667rem 0 0 0;
    }

    .a-btn-text {
      margin-left: 0;
      font-size: $font-small;
      line-height: $line-height-small;
      color: $black;

      @include tablet() {
        font-size: $font-normal;
        line-height: $line-height-medium;
      }

      &::after {
        content: "";
        display: inline-block;
        background-image: url("../../images/icons/arrow-outline-round.svg");
        background-size: $iconSize $iconSize;
        width: $iconSize;
        height: $iconSize;
        vertical-align: middle;
        margin-left: 0.556rem;
      }
    }
  }
}
