@import "@tine/tiff/dist/variables";

$base-font-family: 'Telefon';

// logo
$logo-header: url("../images/logo-tine-blue.svg");
$logo-footer: url("../images/logo-tine-white.svg");

// margins
$column-gutter-half: calc(#{$column-gutter} / 2);
$column-gutter-quarter: calc(#{$column-gutter-half} / 2);
$column-gutter-double: calc(#{$column-gutter} * 2);

@mixin moduleMargin {
   margin: $spacing-mobile;

   @include tablet {
      margin: calc(#{$spacing-tablet} / 2) $spacing-tablet;
   }
   
   @include desktop {
      margin: 23.7px $spacing-desktop;
   }
}
