.color {
   &--white {
      background-color: $alternative-color-white;
   }

   &--lightblue {
      background-color: $alternative-color-lightblue;
   }

   &--green {
      background-color: $alternative-color-green;
   }

   &--dark-green {
      background-color: $alternative-color-dark-green;
   }

   &--blue {
      background-color: $alternative-color-blue;
   }

   &--dark-blue {
      background-color: $alternative-color-dark-blue;
   }

   &--orange {
      background-color: $alternative-color-orange;
   }

   &--yellow {
      background-color: $alternative-color-yellow;
   }

   &--pink {
      background-color: $alternative-color-pink;
   }

   &--purple {
      background-color: $alternative-color-purple;
   }

   &--biola {
      background-color: $alternative-color-biola;
   }

   &--yt {
      background-color: $alternative-color-yt;
   }
}
