/* stylelint-disable */

.plant-list {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  align-items: flex-start;
  position: relative;
  padding: $column-gutter;

  h2 {
    text-align: left;
    font-size: 1.2em;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .link-list {
    display: flex;
    flex-wrap: wrap;

    & > * {
      flex: 1 1 50%;
    }

    ul {
      list-style: none;
      padding-left: 10px;
    }

    li {
      a {
        &::before {
          content: "> ";
          padding-right: 5px;
        }
      }
    }

    & .open {
      display: block;
    }

    @include mobile() {
      & > * {
        flex: 1 1 100%;
        padding-left: 10px;
      }

      & ul {
        display: none;
        margin-bottom: 0;
      }

      li {
        & {
          padding-top: 2px;
        }

        a {
          & {
            margin-left: 0;
          }
        }
      }

      & div {
        margin-right: 0;
        border: 2px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      h2 {
        background: url(../../images/arrow_updown.svg) no-repeat right center;
        background-size: 20px;
        font-size: 1em;
        font-weight: normal;
        margin: 0;
        padding: 0;
        border: none;
      }
    }
  }

  .plant-map {
    display: flex;
    height: 100%;
  }

  #map {
    @include mobile() {
      display: none;
    }
  }
}

table.ltblue tr td:nth-child(1),
table.darkerblue tr td:nth-child(1),
table.blue tr td:nth-child(1),
table.ltrblue tr td:nth-child(1),
table.darkblue tr td:nth-child(1),
table.ltgrey tr td:nth-child(1),
table.grey tr td:nth-child(1),
table.brown tr td:nth-child(1),
table.purple tr td:nth-child(1),
table.green tr td:nth-child(1),
table.darkgrey tr td:nth-child(1),
table.darkergrey tr td:nth-child(1),
table.gold tr td:nth-child(1),
table.darkgreen tr td:nth-child(1),
table.darkpurple tr td:nth-child(1),
table.red tr td:nth-child(1),
table.kremblue tr td:nth-child(1),
table.milk tr td:nth-child(1),
table.solidblue tr td:nth-child(1),
table.solidgreen tr td:nth-child(1),
table.grassgreen tr td:nth-child(1),
table.redmilk tr td:nth-child(1),
table.pink tr td:nth-child(1),
table.ltpink tr td:nth-child(1),
table.milkwhite tr td:nth-child(1),
table.g35red tr td:nth-child(1),
table.snowblue tr td:nth-child(1),
table.kesamblue tr td:nth-child(1),
table.cottagepurple tr td:nth-child(1),
table.jarlsbergred tr td:nth-child(1),
table.kybosdark tr td:nth-child(1),
table.myse tr td:nth-child(1),
table.orange tr td:nth-child(1),
table.ltorange tr td:nth-child(1),
table.golden tr td:nth-child(1) {
	width: 20%;
	text-align: center;
  border-bottom: 0.6em solid #edf5d9;
}

table.ltblue tr td:nth-child(2),
table.darkerblue tr td:nth-child(2),
table.blue tr td:nth-child(2),
table.ltrblue tr td:nth-child(2),
table.darkblue tr td:nth-child(2),
table.ltgrey tr td:nth-child(2),
table.grey tr td:nth-child(2),
table.brown tr td:nth-child(2),
table.purple tr td:nth-child(2),
table.green tr td:nth-child(2),
table.darkgrey tr td:nth-child(2),
table.darkergrey tr td:nth-child(2),
table.gold tr td:nth-child(2),
table.darkgreen tr td:nth-child(2),
table.darkpurple tr td:nth-child(2),
table.red tr td:nth-child(2),
table.kremblue tr td:nth-child(2),
table.milk tr td:nth-child(2),
table.solidblue tr td:nth-child(2),
table.solidgreen tr td:nth-child(2),
table.grassgreen tr td:nth-child(2),
table.redmilk tr td:nth-child(2),
table.pink tr td:nth-child(2),
table.ltpink tr td:nth-child(2),
table.milkwhite tr td:nth-child(2),
table.g35red tr td:nth-child(2),
table.snowblue tr td:nth-child(2),
table.kesamblue tr td:nth-child(2),
table.cottagepurple tr td:nth-child(2),
table.jarlsbergred tr td:nth-child(2),
table.kybosdark tr td:nth-child(2),
table.myse tr td:nth-child(2),
table.orange tr td:nth-child(2),
table.ltorange tr td:nth-child(2),
table.golden tr td:nth-child(2) {
	width: 50%;
	padding-left: 3em;
  background-color: #fefefe;
  border-bottom: 0.6em solid #edf5d9;
}

table.ltblue tr td:nth-child(3),
table.darkerblue tr td:nth-child(3),
table.blue tr td:nth-child(3),
table.ltrblue tr td:nth-child(3),
table.darkblue tr td:nth-child(3),
table.ltgrey tr td:nth-child(3),
table.grey tr td:nth-child(3),
table.brown tr td:nth-child(3),
table.purple tr td:nth-child(3),
table.green tr td:nth-child(3),
table.darkgrey tr td:nth-child(3),
table.darkergrey tr td:nth-child(3),
table.gold tr td:nth-child(3),
table.darkgreen tr td:nth-child(3),
table.darkpurple tr td:nth-child(3),
table.red tr td:nth-child(3),
table.kremblue tr td:nth-child(3),
table.milk tr td:nth-child(3),
table.solidblue tr td:nth-child(3),
table.solidgreen tr td:nth-child(3),
table.grassgreen tr td:nth-child(3),
table.redmilk tr td:nth-child(3),
table.pink tr td:nth-child(3),
table.ltpink tr td:nth-child(3),
table.milkwhite tr td:nth-child(3),
table.g35red tr td:nth-child(3),
table.snowblue tr td:nth-child(3),
table.kesamblue tr td:nth-child(3),
table.cottagepurple tr td:nth-child(3),
table.jarlsbergred tr td:nth-child(3),
table.kybosdark tr td:nth-child(3),
table.myse tr td:nth-child(3),
table.orange tr td:nth-child(3),
table.ltorange tr td:nth-child(3),
table.golden tr td:nth-child(3) {
	width: 30%;
  background-color: #fefefe;
  border-bottom: 0.6em solid #edf5d9;
}

table.blue tr td:nth-child(1) {
	background-color: #285b8c;
  color: #fff;
}

table.darkerblue tr td:nth-child(1) {
	background-color: #002f58;
  color: #fff;
}

table.darkblue tr td:nth-child(1) {
	background-color: #003a78;
  color: #fff;
}

table.ltblue tr td:nth-child(1) {
	background-color: #79b8e3;
  color: #fff;
}

table.ltrblue tr td:nth-child(1) {
	background-color: #abc8e7;
}

table.ltgrey tr td:nth-child(1) {
	background-color: #d9cecb;
}

table.grey tr td:nth-child(1) {
	background-color: #bfc6ca;
}

table.brown tr td:nth-child(1) {
	background-color: #c4a271;
}

table.purple tr td:nth-child(1) {
	background-color: #bf96ae;
  color: #fff;
}

table.green tr td:nth-child(1) {
	background-color: #c3c394;
  color: #fff;
}

table.darkgrey tr td:nth-child(1) {
	background-color: #969ea1;
}

table.darkergrey tr td:nth-child(1) {
	background-color: #82898c;
}

table.gold tr td:nth-child(1) {
	background-color: #dece43;
}

table.darkgreen tr td:nth-child(1) {
	background-color: #044b23;
  color: #fff;
}

table.darkpurple tr td:nth-child(1) {
	background-color: #943259;
  color: #fff;
}

table.red tr td:nth-child(1) {
	background-color: #e42143;
  color: #fff;
}

table.golden tr td:nth-child(1) {
	background-color: #e0ae17;
}

table.kremblue tr td:nth-child(1) {
	background-color: #1c6bad;
  color: #fff;
}

table.milk tr td:nth-child(1) {
	background-color: #e6efce;
}

table.solidblue tr td:nth-child(1) {
	background-color: #2a4a85;
  color: #fff;
}

table.solidgreen tr td:nth-child(1) {
	background-color: #237036;
  color: #fff;
}

table.grassgreen tr td:nth-child(1) {
	background-color: #74a235;
  color: #fff;
}

table.redmilk tr td:nth-child(1) {
	background-color: #de1e35;
  color: #fff;
}

table.pink tr td:nth-child(1) {
	background-color: #ee3897;
  color: #fff;
}

table.ltpink tr td:nth-child(1) {
	background-color: #f8b4c5;
}

table.milkwhite tr td:nth-child(1) {
	background-color: #e0ddd4;
}

table.g35red tr td:nth-child(1) {
	background-color: #920913;
}

table.snowblue tr td:nth-child(1) {
	background-color: #014bad;
  color: #fff;
}

table.kesamblue tr td:nth-child(1) {
	background-color: #99dee5;
}

table.cottagepurple tr td:nth-child(1) {
	background-color: #3e1f87;
  color: #fff;
}

table.jarlsbergred tr td:nth-child(1) {
	background-color: #b8312b;
  color: #fff;
}

table.kybosdark tr td:nth-child(1) {
	background-color: #1b1d1c;
  color: #fff;
}

table.myse tr td:nth-child(1) {
	background-color: #ded7bc;
}

table.orange tr td:nth-child(1) {
	background-color: #ed620d;
  color: #fff;
}

table.ltorange tr td:nth-child(1) {
	background-color: #eda55b;
}
